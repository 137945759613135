import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import DefaultLayout from '../components/layout'
import SEO from '../components/seo'
import ImgWithOrient from "../components/imageOrient";
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"

class BlogIndex extends React.Component {
/*  componentDidMount() {
    // Preload all fullview images
    const posts = data.allMarkdownRemark.edges
    {
      posts.map(({ node }) => {
        const img = new Image();
        img.src = node.frontmatter.fullview;
      }
    }
  } */

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <DefaultLayout>
        <SEO
          title={siteTitle}
          keywords={[`Книга Букв`]}
        />
        <div className="clearfix front-headers">
          <h2><a href="/authors/">Максим&nbsp;Гурбатов&nbsp;<em>и</em> Анна&nbsp;Чайковская</a></h2>
          <h1>Книга Букв</h1>
          <h3><a href="/contents/"><em>самый первый литерный проект</em></a></h3>
        </div>

        <div className="content-box">
          {posts.map(({ node }) => {
            return (
              <article className="post" key={node.fields.slug}>
                {node.frontmatter.preview &&
                  node.frontmatter.preview.childImageSharp &&
                  node.frontmatter.preview.childImageSharp.gatsbyImageData && (
                    <Link
                      to={node.fields.slug}
                      className="post-thumbnail"
                      style={{
                        backgroundImage: `url(${node.frontmatter.preview.childImageSharp.gatsbyImageData.images.fallback.src})`,
                      }}
                    />
                  )}
                  <a className="fullview" href={node.fields.slug}>
                  <figure>
                    <ImgWithOrient
                      aspectRatio={node.frontmatter.fullview.childImageSharp.resize.aspectRatio}
                      image={
                        node.frontmatter.fullview.childImageSharp.gatsbyImageData
                      }
                      key={
                        node.frontmatter.fullview.childImageSharp.gatsbyImageData.src
                      }
                      objectFit="contain"
                      alt={node.frontmatter.title}
                    />
                  </figure>
                  <h5 dangerouslySetInnerHTML={{ __html: node.frontmatter.title }} />
                  <p dangerouslySetInnerHTML={{ __html: node.frontmatter.description }} />
                  <p className="forsale" dangerouslySetInnerHTML={{ __html: node.frontmatter.for_sale }} />
                  </a>
              </article>
            )
          })}
        </div>
        <div className="footnotes clearfix incentered">
          <p>Тексты, объекты, иллюстрации и вообще всё: М.Гурбатов, А.Чайковская &copy; 2021</p>
          <p>В 2021 году издан компендиум проекта в виде книги
            ограниченного&nbsp;тиража&nbsp;с&nbsp;нумерованными экземплярами,
            со&nbsp;всеми&nbsp;приличествующими комментариями и примечаниями.<br />
            Тираж: 299 экземпляров. Осталось в продаже: 92</p>
          <p>Большая часть прекрасных работ проекта уже нашла своих счастливых владельцев,
            но некоторые работы и книги ещё можно купить за деньги:<br />
            <a href="mailto:ma.gu@bk.ru">ma.gu@bk.ru</a> <a href="tel:+36204887451">+36 20 488 7451</a><br />
            или<br />
            <a href="mailto:anna.chay@yandex.ru">anna.chay@yandex.ru</a> <a href="tel:+36209960175">+36 20 996 0175</a>
          </p>
          <p><em>Будапешт</em> Fecit<br />
            <span className="brand">mmxxi</span></p>
        </div>
      </DefaultLayout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(blog)/"  }}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY, MMM DD")
            title
            description
            for_sale
            preview {
              childImageSharp {
                gatsbyImageData(width: 250, formats: [JPG])
              }
            }
            fullview {
              childImageSharp {
                gatsbyImageData(formats: [JPG])
                resize {
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  }
`
