import React from "react";
import { GatsbyImage } from 'gatsby-plugin-image'

// we only care about `aspectRatio`, the rest will be passed directly to `Img`
const ImgWithOrient = ({ aspectRatio, ...props }) => {
  let orientation;
  if (aspectRatio >= 1.2 && aspectRatio <= 2) orientation = "landscape";
  if (aspectRatio <= 0.8 && aspectRatio >= 0.5) orientation = "portrait";
  if (aspectRatio > 0.8 && aspectRatio < 1.2) orientation = "square";
  if (aspectRatio > 2) orientation = "xlandscape";
  if (aspectRatio < .5) orientation = "xportrait";

  return <GatsbyImage className={`${orientation}`+ " fullview-image"} {...props} />;
};

export default ImgWithOrient;